/* UploadProject.css */

.upload-project {
    /* background-color: #f9f9f9; */
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    padding: 0px;
    margin: 0px auto;
   
  }
  
  .upload-project h2 {
    font-size: 25px;
    color: #ed7e2f;
    margin-bottom: 15px;
  }
  
  .upload-project .form-group {
    margin-bottom: 20px;
  }
  
  .upload-project label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    color:black;
    text-align: left;
    width:15%;
  }
  
  .upload-project input,
  .upload-project textarea {
    width: 50%;
    padding: px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s;
  }
  
  .upload-project input:focus,
  .upload-project textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .upload-project textarea {
    height: 50px; /* Set a reduced height for the description field */
    resize: vertical; /* Allow vertical resizing */
  }
  
  .upload-project button {
    width: 20%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .upload-project button:hover {
    background-color: #0056b3;
  }
  
  .upload-project p {
    margin-top: 15px;
    text-align: center;
    color: rgb(236, 245, 236);
    font-weight: bold;
  }
  

  .upload-status-message {
    position: fixed; /* Fixed positioning for the overlay effect */
    top: 20px; /* Position from the top */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center the overlay */
    background-color: rgba(235, 147, 23, 0.8); /* Semi-transparent green background */
    color: white; /* Text color */
    padding: 10px 20px; /* Padding for better appearance */
    border-radius: 5px; /* Rounded corners */
    z-index: 1000; /* Ensure it appears above other content */
    transition: opacity 0.5s ease; /* Optional: Smooth transition for visibility */
    display: flex; /* Align items if needed */
    align-items: center; /* Center align items vertically */
    justify-content: center; /* Center align items horizontally */
  }