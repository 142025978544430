/* General container styling */
.me-section-content {
    padding: 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f5f5f5; /* Light background to make sections stand out */
    background-image: linear-gradient( 76.3deg,  rgb(43, 56, 68) 12.6%, rgba(36, 51, 65,1) 82.8% );

    min-height: 100vh;
  }
  
  /* Flex container to align the sections side by side */
  .flex-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 25px; /* Add some space between the sections */
  }
  .flex-container h2 {
    color:#FF944F;
    font-size: 20x;
    font-weight: 500;
  }

 
  
  /* Styles for both sections */
  .employee-assignment-section,
  .sub-manager-details,.convert-to-sub-manager,.uploading-projects {
    flex: 1;
    background-color: #fff;
    padding: 20px;
    border-radius: 6px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    transition: box-shadow 0.3s ease; /* Smooth transition for hover effect */
  }
  
  /* Section titles */
  . .sub-manager-details h2 {
    margin-bottom: 20px;
    font-size: 20px;
    color: #333;
    border-bottom: 2px solid #e39313; /* Underline the section titles */
    padding-bottom: 10px;
  }
  
  /* Form group spacing */
  .form-group {
    margin-bottom: 20px;
  }
  
  /* Dropdown styling */
  .dropdown {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: border-color 0.3s ease;
  }
  
  .dropdown:hover,
  .dropdown:focus {
    border-color: #df7614; /* Highlight dropdown on hover/focus */
  }
  
  /* Container for checkboxes in the dropdown */
  .dropdown-container {
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  /* Checkbox item styling */
  .checkbox-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .checkbox-item input {
    margin-right: 10px;
  }
  
  .checkbox-item label {
    color: #333;
    cursor: pointer;
    transition: color 0.2s ease;
  }
  
  .checkbox-item:hover label {
    color: #007bff; /* Change label color on hover */
  }
  
  /* Employee list in the second section */
  .employee-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .employee-list li {
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    color: #555;
    transition: color 0.2s ease;
  }
  
  .employee-list li:hover {
    color: #007bff; /* Change text color on hover */
  }
  
  /* Adding a slight hover effect to the section box */
  .employee-assignment-section:hover,
  .sub-manager-details:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Make the shadow a bit stronger on hover */
  }
  
  /* Scrollbar styling */
  .dropdown-container::-webkit-scrollbar {
    width: 6px;
  }
  
  .dropdown-container::-webkit-scrollbar-thumb {
    background-color: #007bff; /* Make scrollbar thumb match the theme color */
    border-radius: 5px;
  }
  
  /* Dropdown header for employees */
.dropdown-header {
    padding: 12px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .dropdown-header:hover {
    background-color: #e0e0e0;
  }
  
  /* Scrollable checkbox dropdown for employees */
  .dropdown-container {
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 5px;
  }
  
  .checkbox-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .checkbox-item input {
    margin-right: 10px;
  }
  
  /* Button styling */
  .assign-button {
    padding: 12px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }
  
  .assign-button:hover {
    background-color: #FF944F;
  }
  
  .assign-button:focus {
    outline: none;
  }


  
/* Employee to manager conversion css */
.search-container {
    margin-bottom: 20px;
    align-items: centers;
    padding-bottom: 10px;
    margin: 0;
    justify-content: flex-start;
}

.search-input {
    flex-grow: 1;
    width: 90%;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.employee-list {
    max-height: 200px; /* Limit height for scrolling */
    overflow-y: auto; /* Enable scrolling */
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 20px;
    color:black;
    padding-left: 10px;
}

.employee-row {
    display: flex;
    align-items: center; /* Aligns items vertically */
     margin-bottom: 8px;  /* Space between items */
    padding: 10px;
    border-bottom: 1px solid #eee; /* Divider between employees */
}


.employee-row:last-child {
    border-bottom: none; /* Remove last divider */
}

.convert-button {
  padding: 12px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.convert-button:hover {
    background-color: #FF944F; /* Darker green on hover */
}
.local-label {
  color: black;
  font-weight: 400; /* Ensure text is visible */
}


.dialog-conversion {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  transform: translate(-50%, -50%); /* Centering the dialog */
  width: 700px; /* Adjust as needed */
  height: 200px; /* Adjust as needed */
  /* Semi-transparent background */
  background-image: linear-gradient( 76.3deg,  rgb(39, 54, 68) 12.6%, rgb(32, 45, 57) 82.8% );
  border: 3px solid #da810e;
  border-radius: 5px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Hide any overflow from animation */
  z-index: 1000;
}

.dialog-conversion-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Send animation to the back */
}

.dialog-conversion-message {
  position: relative; /* Make message position relative to its container */
  z-index: 2; /* Ensure message is on top of the animation */
  color: white; /* Change color as per your design */
  font-size: 16px; /* Adjust font size as needed */
  text-align: center; /* Center the message */
  margin: 0; /* Remove default margins */
}

/* Optional: Add a close button style */
.conversion-close-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 2;
  margin-top: 10px; /* Space between message and button */
  padding: 10px 15px; /* Padding for the button */
  background-color: #FF944F; /* Button background color */
  color: white; /* Text color */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.conversion-close-button:hover {
  background-color: #FF944F; /* Darker blue on hover */
}

.employee-list p {
  margin-left:10px;
}