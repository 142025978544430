.NotificationModal-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .NotificationModal-modal-content {
    background: #ffffff;
    background-image: linear-gradient( 76.3deg,  rgba(44,62,78,1) 12.6%, rgba(44,62,78,1) 82.8% );
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative; /* Ensures the close icon is positioned absolutely within this container */
    animation: fadeIn 0.3s ease;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .modal-icon {
    color: #f44336;
    font-size: 30px;
    margin-right: 7px; /* Space between icon and text */
  }
  
  .modal-message {
    font-size: 16px;
    color:white;
    line-height: 1.5;
    flex: 1; /* Take up remaining space next to the icon */
  }
  
  .modal-close-icon {
    position: absolute;
    top: 15px;
    bottom: 15px;
    right: 15px;
    font-size: 20px;
    cursor: pointer;
    color:white;
    transition: color 0.2s ease;
  }
  
  .modal-close-icon:hover {
    color: rgb(229, 128, 51); /* Change color on hover for better visibility */
  }
  