/* AcknowledgmentButton.css */

/* Container for the acknowledgment button and message */
.acknowledgment-container {
    position: relative; /* Relative positioning for inner absolute elements */
    display: flex; /* Use flexbox for alignment */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
}

/* Styles for the acknowledgment button */
.acknowledgment-button {
    background-color: #4CAF50; /* Green background */
    color: white;              /* White text */
    border: none;              /* Remove default border */
    border-radius: 5px;       /* Rounded corners */
    padding: 10px 20px;       /* Padding for button */
    font-size: 16px;          /* Font size */
    cursor: pointer;           /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Transition effect */
    margin-top: 10px;         /* Space above button */
}

/* Change background color on hover */
.acknowledgment-button:hover {
    background-color: #45a049; /* Darker green */
}

/* Styles for the success message */
.success-message {
    position: absolute;                /* Position it absolutely */
    top: 50%;                          /* Center vertically */
    left: 20%;  
    padding-left:50px;
                        /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the message */
    background-color: #eb740c;        /* Green background */
    color: white;                      /* White text */
    padding: 10px 15px;               /* Padding for message */
    border-radius: 5px;               /* Rounded corners */
    z-index: 1000;                    /* Ensure it's above other elements */
    animation: fade-in-out 3s ease; 
    width: 400px  /* Animation for the message */
}

/* Animation for the success message */
@keyframes fade-in-out {
    0% {
        opacity: 0; /* Start invisible */
    }
    10% {
        opacity: 1; /* Fade in */
    }
    90% {
        opacity: 1; /* Keep it visible */
    }
    100% {
        opacity: 0; /* Fade out */
    }
}
