.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.App-body {
  padding: 20px;
}

.App-footer {
  background-color: #282c34;
  padding: 10px;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.Login, .Register {
  max-width: 300px;
  margin: auto;
}

.Login form, .Register form {
  display: flex;
  flex-direction: column;
}

.Login form div, .Register form div {
  margin-bottom: 10px;
}

button {
  background-color: #61dafb;
  border: none;
  padding: 10px;
  cursor: pointer;
}

button:hover {
  background-color: #21a1f1;
}

a {
  color: #61dafb;
}
