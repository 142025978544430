.assign-project-container {
    padding: 0px;
    border-radius: 8px;
  }
  
  .assign-project-form {
    display: flex;
    flex-direction: column; /* Stack form elements vertically */
  }
  
  h2 {
    color: #333; /* Dark text color */
  }
  
  .assign-project-form-group {
    margin-bottom: 15px; /* Space between form groups */
  }

  .assign-project-container .assign-project-form-group label {
    font-weight: bold;
    color: #333; /* Set a specific color for the labels */
    margin-bottom: 8px;
    display: block;
    width:22%;
  }

  
  select {
    width: 40%; /* Full width for dropdowns */
    padding: 10px; /* Padding for better touch area */
    border: 1px solid #ccc; /* Border style */
    border-radius: 4px; /* Rounded corners */
    font-size: 16px; /* Font size */
    transition: border-color 0.3s; /* Transition effect for focus */
  }
  
  select:focus {
    border-color: #007bff; /* Change border color on focus */
    outline: none; /* Remove default outline */
  }
  
  .assign-project-button {
    width: 22%; /* Full width button */
    padding: 10px; /* Button padding */
    background-image: linear-gradient(to right, #FF512F 0%, #F09819  51%, #FF512F  100%);
    color: white; /* Text color */
    border: none; /* No border */
    border-radius: 4px; /* Rounded corners */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Transition effect for hover */
  }
  
  .assign-project-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
 /* Overlay status message */
.assign-project-status-message-overlay {
  position: fixed; /* Keeps it floating above everything */
  top: 50%; /* Centers vertically */
  left: 50%; /* Centers horizontally */
  transform: translate(-50%, -50%); /* Perfect center */
  background-color: rgba(231, 121, 17, 0.8); /* Slight transparency with a dark background */
  color: white;
  padding: 20px;
  border-radius: 8px;
  font-size: 18px;
  z-index: 1000; /* High z-index to ensure it appears above everything */
  text-align: center;
  max-width: 300px;
  width: 100%;
}
  