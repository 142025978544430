.reviewed-reports {
    padding: 20px;
}

.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.dashboard-header h1 {
    font-size: 24px;
}

.dashboard-header input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.reports-table {
    margin-top: 20px;
}

.reports-table table {
    width: 100%;
    border-collapse: collapse;
}

.reports-table th, .reports-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.reports-table th {
    background-color: #f2f2f2;
}
