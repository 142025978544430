.history-container {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    min-height: 100vh;
}

.history-header {
    position: fixed;
    background-color: #6c757d; /* Matching the color scheme of Employee.js header */
    border-bottom: 1px solid #ddd; /* Matching border style if used in Employee.js */
    padding: 1rem;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for a subtle effect */
    z-index: 1000; /* Ensures it appears above other elements */
    left:0;
    right:0;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}


.header-logo {
    height: 50px; /* Adjust size based on your logo */
    margin-right: 1rem;
}

.history-header-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 28px;
    font-weight:bolder;
    color: #FF944F;
}

.history-body {
    flex: 1;
    padding: 2rem;
    background-color: #f4f4f9;
    background-image: linear-gradient( 76.3deg,  rgba(44,62,78,1) 12.6%, rgba(69,103,131,1) 82.8% );
}

.history-table {
    width: 100%;
    border-collapse: collapse;
    margin-top:10px;
    background-color: white;
    border-radius: 4px;
    overflow: hidden; /* Ensures that the border radius applies to the whole table */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.history-table th, .history-table td {
    border: 1.5px solid #a18686;
    padding: 0.8rem;
    text-align: center;
    border-bottom: 1.5px solid #927d7d;
    vertical-align: middle;
    font-size: 13px;
}

.history-table th {
    background-color: #f0af55; /* Adjust color to match Employee.js */
    color: white;
    font-weight: bold;
    text-align: center;
}

.history-footer {
    background-color: #333; /* Matching the color scheme of Employee.js footer */
    color: white;
    text-align: center;
    padding: 1rem;
    position: relative;
    bottom: 0;
    width: 100%;
}
.employee-name {
    /* color:black; */
}

/* new css */

.history-container {
    background-image: linear-gradient( 76.3deg,  rgba(44,62,78,1) 12.6%, rgba(69,103,131,1) 82.8% );
}

.history-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #6c757d; /* Matching the color scheme of Employee.js header */
    border-bottom: 1px solid #ddd; /* Matching border style if used in Employee.js */
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for a subtle effect */
    position: fixed;
    z-index: 1000;
}

.header-content {
    display: flex;
    align-items: center;
}

.header-logo {
    width: 80px;
}

.history-header-title {
    margin-left: 20px;
    font-size: 24px;
    font-weight:900;
    left: 50%;
}

.employee-info {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.profileicon {
    width: 40px;
    margin-right: 10px;
}

.employee-details {
    display: flex;
    flex-direction: column;
}

.employee-name {
    font-size: 18px;
    font-weight: bold;
}

.employee-job {
    font-size: 14px;
    color: rgb(220, 119, 46);
}

.history-body {
    margin-top: 75px;
}

.quarter-cards-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.quarter-card {
    padding: 0px 20px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    background-color: #fff;
}

.history-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 15px;
}

.history-table th, .history-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #dee2e6;
}

.quarter-review {
    margin-top: 0px;
}

.quarter-buttons {
    margin-top: 0px;
    display: flex;
    gap: 10px;
    margin-bottom: 0px;
}
/* review section */
/* Styles for the review section (ratings and buttons) */
.quarter-review-section {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    /* margin-top: 10px; */
    padding: 0px 0;
    border-top: 1px solid #dee2e6;
}

/* Display final rating and reviewer rating side by side */
.quarter-review-details {
    display: flex;
    align-items: center;
    gap: 30px;
}

/* Reviewer comment styled as a card-like box */
.reviewer-comment-box {
    border-radius: 1px;
    padding: 13px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    flex-grow: 1;
    margin-bottom: 20px;
    background-color: #f1ddc7;
}

/* Styles for Final Rating and Reviewer Rating badges */
/* .rating-badge {
    padding: 10px 10px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 1px;
    color: white;
} */

.final-rating {
    color:black;
    display: flex; /* Use flex for alignment */
    align-items: center; /* Center vertically */
    white-space: nowrap; /* Prevent wrapping */
    padding: 13px; /* Padding around the badge */
    border-radius: 1px; /* Rounded corners */
    font-size: 16px; /* Font size */
    font-weight: bold; /* Bold text */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f1ddc7;
}


.quarter-buttons {
    display: flex;
    gap: 10px;
}

.acknowledgement-button, .download-button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 4px;
}

.acknowledgement-button:hover, .download-button:hover {
    background-color: #4acf21;
}
