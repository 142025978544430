.lead-management-container {
  padding: 20px;
  font-family: Arial, sans-serif; /* Use a clean, modern font */
  background-color: #f0f4f8; /* Light background color for contrast */
  border-radius: 2px; /* Rounded corners for the container */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

h3 {
  margin-top: 0;
  color: #333; /* Dark color for headings */
  margin-bottom: 20px;
}

.lead-management-nav {
  display: flex;
  justify-content: center; /* Center nav items */
  margin-bottom: 20px; /* Space below nav */
}

.lead-management-nav button {
  padding: 10px 15px;
  margin: 0 10px;
  border: none;
  border-radius: 3px;
  background-color: #007bff; 
  color: white; /* Button text color */
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth background transition */
}

.lead-management-nav button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.lead-management-nav .active {
  background-color: #ef890c; /* Active button color */
}

.horizontal-sections {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Allow sections to wrap */
}

.section {
  flex: 1; /* Allow sections to grow equally */
  min-width: 300px; /* Minimum width for each section */
  margin: 10px; /* Space between sections */
  padding: 20px; /* Padding inside sections */
  border-radius: 1px; /* Rounded corners for sections */
  background-color: white; /* White background for sections */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); /* Slight shadow for sections */
  transition: transform 0.2s; /* Smooth hover effect */
}

.section:hover {
  transform: scale(1.02); /* Slight scale effect on hover */
}

@media (max-width: 768px) {
  .horizontal-sections {
    flex-direction: column; /* Stack sections vertically on smaller screens */
  }
}
