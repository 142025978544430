/* ProfileDialog.css */
.profile-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.profile-dialog-content {
    background: #f5f5f5;
    background-image: radial-gradient( circle 1292px at -13.6% 51.7%,  rgba(0,56,68,1) 0%, rgba(0,56,68,1) 51.5%, rgba(0,56,68,1) 88.6% );
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 800px;
    padding: 20px;
    display: flex;
    flex-direction: column;
   

}

.profile-dialog-header {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-dialog-header h2 {
    margin: 0;
    font-size: 1.5em;
    color: #f19d36;
    
}

.profile-dialog-header img {
    width: 70px; /* Adjust size as needed */
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
    background-color: orangered;
}


.profile-dialog-grid {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns: one for labels, one for inputs */
    gap: 15px;
    margin-top: 25px;
    align-items: center;
}
.profile-dialog-row {
    display: flex;
    align-items: center; /* Center align label and input vertically */
}

.profile-dialog-grid label {
    font-weight: bold;
    color: rgb(244, 239, 239); /* Set label text color to black */
    align-self: center; /* Center align label vertically */
    max-width: 300px; /* Adjust this based on label length */
    white-space: nowrap; /* Prevent label text from wrapping */
}

.profile-dialog-grid input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Ensure padding is included in width */
    grid-column: 2/3;
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 0px;
}

.form-actions button {
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #f7a84e;
    color: #fff;
    font-size: 1em;
}

.form-actions button:hover {
    background-color: #e8a11c;
}

.form-actions button.cancel {
    background-color: #6c757d;
}

.form-actions button.cancel:hover {
    background-color: #5a6268;
}
.success-message {
    background-color: #d4edda;
    color: #155724;
    padding: 10px;
    margin-top: 20px;
    border: 1px solid #c3e6cb;
    border-radius: 4px;
    text-align: center;
}
.icon {
    margin-left: 8px;
    color: #f7a84e;
}
.profile-dialog-grid input:disabled {
    background-color: #b1b3b7; /* Light gray background for disabled inputs */
    color: #1f1f20; /* Darker gray text color for readability */
    border: 1px solid #ced4da; /* Slightly darker border to distinguish it from active inputs */
    cursor: not-allowed; /* Change cursor to indicate it's disabled */
}