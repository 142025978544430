body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

.Login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; 
  height: 100vh;
  background-image: linear-gradient( 76.3deg,  rgba(44,62,78,1) 12.6%, rgba(69,103,131,1) 82.8% );
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0; 
  /* filter: blur(8px);
  z-index: -1; */
 
}

.Header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align items to the left */
  padding-top:10px;
  padding-left: 35px;
}

.Logo {
  height: 70px;
  padding-left: 20px;
  margin: 0;
  background-color: transparent;
}

.Header1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding-bottom: 15px;
  margin-top: 0px;
}

.Main-heading {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin: 0;
  padding: 10px 0; /* Adjusted padding */
  color:#FF944F;
  /* color: rgb(235, 118, 16); */
  font-weight: 900;
  font-size: 33px;

}

.Login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border: 1px solid #e8e1e1;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* background-color: #0093E9;
  background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%); */
  /* background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%); */
  /* background-image: linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% ); */
  /* background-image: linear-gradient( 109.9deg,  rgb(234, 209, 174) 1.8%, rgb(235, 214, 186) 95.4% ); */
  background-image: linear-gradient( 76.3deg,  rgba(44,62,78,1) 12.6%, rgba(69,103,131,1) 82.8% );
  max-width: 420px;
  width: 100%;
  margin: 10px auto;
  z-index: 1;
  position: relative;
  
}

.Login h2 {
  margin-bottom: 20px;
  color:#FF944F;
  /* color: #efe2e2; */
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

form div {
  display:flex;
  margin-bottom: 15px;
}

label {
  padding-top: 8px;
  display:block;
  padding-left: 12px;
  margin-bottom: 5px;
  font-weight: bold;
  align-self: flex-start;
  color:white;
}
.login-emaillogin{
  padding-left: 30px ;
}

input {
  padding: 10px;
  border: 2px solid #c0b7b1;
  border-radius: 5px;
  font-size: 16px;
  margin-left: 20px;
}


input:focus {
  border-color: #f08c19;
  outline: none;
}
.login-btn{
  padding: 10px;
 
  background-image: linear-gradient(to right, #FF512F 0%, #F09819  51%, #FF512F  100%);
  /* background-color: #007bff; */
  color: white;
  transition: 0.5s;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width:50%;
  margin-left: 26%;
}



.login-btn:hover {
  background-position: right center;
  /* background-color: #000000; */
  color:rgb(238, 228, 228);
}

.login-p {
  color:white;
  margin-top: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .Login {
    padding: 15px;
    margin: 10px;
  }

  h2 {
    font-size: 1.5em;
  }

  button {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .Login {
    padding: 10px;
    margin: 5px;
  }

  h2 {
    font-size: 1.3em;
  }

  input {
    font-size: 14px;
  }

  button {
    font-size: 12px;
  }
}


/* Base styling for the login status message */
.login-status {
  font-size: 14px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

/* Success message styling */
.login-status.success {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
}

/* Error message styling */
.login-status.error {
  color: #e61126;
  background-color: #f7f3f4;
  border: 1px solid #f5c9aa;
}

  /* Notification overlay */
  .Notification-overlay {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    
    /* background-image: radial-gradient( circle 815px at 23.4% -21.8%,  rgba(9,29,85,1) 0.2%, rgba(0,0,0,1) 100.2% ); */
    background-color: rgba(250, 250, 250, 0.9);
    color: rgb(234, 13, 13);
    padding: 7px 20px;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    animation: fadeInOut 3s ease-in-out;
  }
  
  /* Notification fade in/out animation */
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }