.assign-leads {
    max-width: 400px; /* Set a maximum width for the container */
    margin: 20px auto; /* Center the container */
    padding: 20px; /* Add some padding */
    border: 1px solid #ddd; /* Light border around the container */
    border-radius: 8px; /* Rounded corners */
    background-color: #f9f9f9; /* Light background color */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  .assign-leads h3 {
    text-align: center; /* Center the heading */
    color: #333; /* Dark color for text */
    margin-bottom: 20px; /* Space below the heading */
  }
  
  .assign-leads label {
    display: block; /* Each label on a new line */
    margin-bottom: 8px; /* Space below labels */
    font-weight: bold; /* Make the labels bold */
    color: #555; /* Slightly lighter color for labels */
  }
  
  .assign-leads select {
    width: 100%; /* Full width for select elements */
    padding: 10px; /* Padding for better touch target */
    margin-bottom: 20px; /* Space below select elements */
    border: 1px solid #ccc; /* Light border */
    border-radius: 4px; /* Rounded corners for select elements */
    font-size: 16px; /* Slightly larger font for better readability */
  }
  
  .assign-leads button {
    width: 100%; /* Full width for the button */
    padding: 10px; /* Padding for better touch target */
    background-image: linear-gradient(to right, #FF512F 0%, #F09819  51%, #FF512F  100%);
    color: white; /* White text color */
    border: none; /* No border */
    border-radius: 4px; /* Rounded corners */
    font-size: 16px; /* Slightly larger font */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }
  
  .assign-leads button:hover {
    background-color: #45a049; /* Darker green on hover */
  }
  
  .success-message {
    color: #4CAF50; /* Green color for success messages */
    margin-bottom: 15px; /* Space below success messages */
  }
  
  .error-message {
    color: #f44336; /* Red color for error messages */
    margin-bottom: 15px; /* Space below error messages */
  }
  