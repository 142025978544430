.view-leads {
    margin-top: 20px;
  }
  
  .view-leads table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .view-leads th, .view-leads td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .view-leads th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .error-message {
    color: red;
    margin-bottom: 10px;
  }
  .success-message {
    position: fixed;
    top: 10px;
    right: 10px;
    background-color: #4CAF50;
    color: white;
    padding: 10px;
    border-radius: 5px;
    z-index: 1000;
}

.view-leads button {
    padding: 10px 20px;
    background-color: #ed9c22;
    color: white;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}