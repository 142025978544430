.notifications-list {
    padding: 20px;
}

.notifications-list ul {
    list-style-type: none;
    padding: 0;
}

.notifications-list .notification {
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.notifications-list .notification.unread {
    font-weight: bold;
    background-color: #f9f9f9;
}
