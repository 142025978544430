.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 500px;
    max-height: 80%;
    max-height: 90vh; /* Adjust based on your needs */
    overflow-y: auto;
}

.form-group {
    margin-bottom: 20px;
}

.assign-goallabel
{
    color:black;
    
}

/* Dropdown Container */
.assign-dropdown-container {
    position: relative;
    margin-bottom: 20px; /* Space between dropdowns */
}

/* Dropdown Button */
.dropdown-button {
    background: #ecbf6b;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    width: 90%;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Dropdown Menu */
.assign-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    z-index: 1000;
    width: 90%;
    max-height: 200px; /* Adjust based on your needs */
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px 0;
}

/* Checkbox Item */
.checkbox-item {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-bottom: 1px solid #ddd;
}

/* Checkbox Input */
.checkbox-item input[type="checkbox"] {
    margin-right: 10px;
    accent-color: #007bff; /* Custom checkbox color */
}

/* Checkbox Label */
.checkbox-item label {
    font-size: 14px;
    color: #333;
}




.button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.assign-button, .close-button {
    /* padding: 10px 20px; */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    
}
.assigngoal-assign-button{
    border-radius: 5px;
    color:white;
    cursor: pointer;
    background-color: #007bff;
    
}

.assign-button {
    background-color: #28a745;
    color: white;
}

.close-button {
    background-color: #007bff;
    color: white;
    padding-top: 50px;
    margin: 0 !important;
    padding-top:0px;
    padding-bottom:0px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display:inline-block;
    padding-bottom: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 20px;
    padding-right: 20px;
}
.update-message{
    background-color: #dfe3df;
    color:green;
    padding: 10px 20px;
    border-radius: 3px;
    font-size: 16px;
    box-shadow: 0 2px 8px rgba(120, 98, 98, 0.1);
    opacity: 0.9;
    margin-left: 30px;
    margin: 20px auto; /* Center the message block within the modal */
    display: inline-block;
    z-index: 1000; /* Ensure the message block takes only the necessary width */
}


.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.loading-spinner {
    font-size: 3rem;
    color: white;
}