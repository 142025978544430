/* Admin.css */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.admin-page {
    display: flex;
    min-height: 100vh;
    background-image: linear-gradient( 76.3deg,  rgba(44,62,78,1) 12.6%, rgba(69,103,131,1) 82.8% );

}

.sidebar {
    width: 220px; /* Increased width */
    background-image: linear-gradient( 76.3deg,  rgba(44,62,78,1) 12.6%, rgba(69,103,131,1) 82.8% );

    background-color: #333;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1000;
    overflow-y: auto; /* Ensure vertical scroll if needed */
    overflow-x: hidden; /* Prevent horizontal scroll */
}

.logo-container {
    margin: 20px 0;
}

.logo {
    width: 100px;
}

.nav-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.nav-item {
    padding: 15px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    width: 100%;
    display: block;
    border: 1px solid transparent;
}

.nav-item:hover, .nav-item:focus {
    background-color: #575757;
    border: 1px solid #f19412;
    outline: none;
}

.nav-item.active {
    background-color: #575757;
    border: 1px solid #ee810c;
}

.profile-section {
    margin-top: auto; /* Push profile section to the bottom */
    width: 100%;
    padding: 20px;
    background-color: #444; /* Darker background for profile section */
    border-top: 1px solid #555; /* Light border on top */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.profile-picture {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
    cursor: pointer;
}

.profile-name {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
}

.profile-role {
    font-size: 14px;
    color: #bbb;
    cursor: pointer;
}

.main-content {
    margin-left: 220px; /* Adjust margin to match the sidebar width */
    padding: 20px;
    width: calc(100% - 220px); /* Adjust width to match the sidebar width */
    position: relative;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.main-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
}

.profile-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #eb9722;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    font-size: 20px;
    text-align: center;
}

.profile-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 10px; /* Space between icon and name */
    color:white;
   
}

.profile-name {
   font-size: 16px;
    font-weight: bold;
    color: white;
    margin: 0;
}

.profile-role {
    font-size: 14px;
    color: #e3b6b6;
    margin: 0;
}

.sort-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #eb9722;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px; /* Space between search bar and sort button */
}

.sort-button:hover {
    background-color: #0056b3;
}

.welcome-section {
    margin-bottom: 20px;
    color:white;
}

.employee-list-section {
    width: 100%;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    box-sizing: border-box; /* Ensure padding does not affect width */
    overflow: hidden; /* Prevent content from extending outside */
}

.employee-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.controls-left {
    display: flex;
    align-items: center;
}

.controls-right {
    display: flex;
    align-items: center;
}

.employee-table-wrapper {
    width: 100%;
    overflow-x: auto; /* Allow horizontal scroll if necessary for tables */
}

.employee-table {
    width: 100%;
    border-collapse: collapse;
}

.employee-table th,
.employee-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.details-button {
    padding: 5px 10px;
    background-color: #eb9722;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.details-button:hover {
    background-color: #0056b3;
}


.dropbtn {
    background-color: #eb9722;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 5px; /* Add border radius */
    position: relative;
   
}

.dropbtn::after {
    /* content: " ▼"; */
     /* Add dropdown symbol */
    font-size: 12px;
    position: relative;
    right: 5px;
    padding-left:5px
}

.goals-dropdown {
    position: relative;
    display: inline-block;
    padding-left: 15px;
    
}

.goals-dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.goals-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.goals-dropdown-content a:hover {
    background-color: #f1f1f1;
}

.goals-dropdown:hover .goals-dropdown-content {
    display: block;
}

.goals-dropdown:hover .dropbtn {
    background-color: #3e8e41;
}

/* Admin.css */
.details-dialog-overlay {
    position: fixed;
    top: 0;
    left:70px;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    
  }
  
  .details-dialog {
    background: rgb(255, 255, 255);
    border-radius: 8px;
    padding: 20px;
    width: 80%;
    max-width: 800px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
   
  }
  
  .details-dialog h2 {
    margin-top: 0;
  }
  
  .details-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .details-table th,
  .details-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .details-table th {
    background-color: #f4f4f4;
  }
  
  .details-table td {
    word-wrap: break-word;
    max-width: 150px;
  }
  
  .details-dialog button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #ed9c22;
    color: white;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .details-dialog button:hover {
    background-color: #0056b3;
  }
  
/* In your Admin.css file */

.details-dialog select {
    width: 100%;
    padding: 5px;
    margin-top: 5px;
}

.reviewer-comment-section {
    margin-top: 20px;
    padding: 10px;
    border-top: 1px solid #efdfdf;
}

.reviewer-comment-section textarea {
    margin-top: 6px;
    width: 80%;
    height: 40px;
    padding: 10px;
    border: 2px solid #d8ad88;
    border-radius: 4px;
}

.employee-summary {
    margin-bottom: 20px;
    display:flex;
    gap:5px;
}
.summary-item {
    flex: 1;
    padding: 10px;
    background: #f4f4f4;
    border-radius: 4px;
    font-size: 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}


.employee-summary p {
    margin: 5px 0;
    font-size: 14px;
}

.tabledisabled {
    background-color: #cac6c6;
}


.submission-success-message {
    color: #4CAF50; /* Green color for success */
    background-color: #e6f7e9; /* Light green background */
    border: 1px solid #4CAF50; /* Green border */
    padding: 10px 20px; /* Padding inside the message */
    border-radius: 5px; /* Rounded corners */
    font-size: 16px; /* Font size */
    text-align: center; /* Centered text */
    width: fit-content; /* Adjusts the width to the content */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Shadow effect */
    /* opacity: 0; */
     /* Initial opacity for fade-in effect */
    transition: opacity 0.5s ease-in-out; /* Transition for fade-in */
    
    position: absolute; /* Absolute positioning */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for perfect centering */
    z-index: 9999; /* Ensure it appears above other components */
}

.quarter-selector {
    margin-bottom: 1rem;
}

.quarter-selector label {
    margin-right: 0.5rem;
}

.overlay-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10; /* Ensure the overlay is on top */
  }
  
  .overlay-content-loading {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    text-align: center;
    color: #fff; /* White text color */
  }
  
  .loading-text-loading {
    margin-top: 10px; /* Add some spacing between spinner and text */
    font-size: 18px;
    color: #fff;
  }


  .submission-error-message {
    color: #f80909; /* Green color for success */
    background-color: #e6f7e9; /* Light green background */
    border: 1px solid #eb250b; /* Green border */
    padding: 10px 20px; /* Padding inside the message */
    border-radius: 5px; /* Rounded corners */
    font-size: 16px; /* Font size */
    text-align: center; /* Centered text */
    width: fit-content; /* Adjusts the width to the content */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Shadow effect */
    /* opacity: 0; */
     /* Initial opacity for fade-in effect */
    transition: opacity 0.5s ease-in-out; /* Transition for fade-in */
    
    position: absolute; /* Absolute positioning */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for perfect centering */
    z-index: 9999; /* Ensure it appears above other components */
    
}



.loading-overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10; /* Ensure the overlay is on top */
  }
  
  .overlay-content-loading {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    text-align: center;
    color: #fff; /* White text color */
  }
  
  .loading-text-loading {
    margin-top: 10px; /* Add some spacing between spinner and text */
    font-size: 18px;
    color: #fff;
  }
