body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Register-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Changed from center to flex-start */
  min-height: 100vh;
  background-image: linear-gradient( 76.3deg,  rgba(44,62,78,1) 12.6%, rgba(69,103,131,1) 82.8% );
  /* background-image: url('./../Images/image.png'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0; 
}

.Register {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    border: 0.5px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-image: linear-gradient( 76.3deg,  rgba(44,62,78,1) 12.6%, rgba(69,103,131,1) 82.8% );
    max-width: 400px;
    width: 100%;
  }
  
  .Register h2{
      color:#FF944F;
  }
  
  label {
    padding-top: 8px;
    display:block;
    padding-left: 12px;
    margin-bottom: 5px;
    font-weight: bold;
    align-self: flex-start;
    color:white;
  }
  input {
    padding: 10px;
    border: 2px solid #c0b7b1;
    border-radius: 8px;
    font-size: 16px;
    margin-left: 20px;
  }
  .emaillogin{
    padding-left: 51px;
  }
  .employeeId{
     padding-left: 0px;
  }
 .password 
 {
  padding-top: 9px;
  padding-left: 20px;
  padding-bottom: 7px;
 }
  .login-btn {
    margin-top:13px;
    margin-bottom: 10px;
  }
  

  /* Notification overlay */
.Notification-overlay {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  
  /* background-image: radial-gradient( circle 815px at 23.4% -21.8%,  rgba(9,29,85,1) 0.2%, rgba(0,0,0,1) 100.2% ); */
  background-color: rgba(250, 250, 250, 0.9);
  color: rgb(234, 13, 13);
  padding: 7px 20px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: fadeInOut 3s ease-in-out;
}

/* Notification fade in/out animation */
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}




.project-select {
  margin: 1px 0; 
  /* Space around the dropdown */
}

.project-select label {
  display: block; /* Ensure label appears above the dropdown */
  margin-bottom: 5px; /* Space between label and dropdown */
  font-weight: bold; 
  padding-right: 10px;
}

.project-select select {
  width: 60%; /* Full width */
  padding: 10px; /* Padding for comfort */
  border: 2px solid #ccc; /* Light gray border */
  border-radius: 8px; /* Rounded corners */
  font-size: 16px; /* Font size */
  transition: border-color 0.3s;
  color:black; /* Smooth transition for focus */
}

/* Change border color on focus */
.project-select select:focus {
  color:black;
  border-color: #f3870d; /* Highlight border color */
  outline: none; /* Remove default outline */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Optional shadow effect */
}