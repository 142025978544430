.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
}

textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
}

.creatgoalbtn {
    margin-right: 10px;
     background-color: rgb(232, 163, 83);
     border-radius: 3px;
    border-radius: 4px solid white;
    color:white;
}
