.convert-to-lead-container {
    padding: 10px;
    border: 1px solid #d1d1d1;
    border-radius: 6px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: auto;
  }
  
  .convert-to-lead-container h4 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 18px;
    color: #201d1d;
    text-align: center;
  }
  
  .convert-to-lead-container select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    color: #333;
    background-color: #fff;
  }
  
  .convert-to-lead-container select:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .convert-to-lead-container button {
    align-items: center;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-image: linear-gradient(to right, #FF512F 0%, #F09819  51%, #FF512F  100%);

    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .convert-to-lead-container button:hover {
    background-color: #0056b3;
  }
  
  .convert-to-lead-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  

  .message {
    position: fixed;
    top: 20px; /* Adjust the distance from the top */
    left: 50%;
    transform: translateX(-50%); /* Center the message horizontally */
    padding: 10px;
    border-radius: 5px;
    z-index: 1000; /* Ensure it floats above other content */
    transition: opacity 0.3s ease; /* Smooth transition for fading out */
  }
  
  .success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }
  
  .error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }
  