/* Modal Overlay */
.textmodal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the modal is on top */
}

/* Modal Content */
.textmodal-content {
    position: relative;
    width: 90%;
    max-width: 500px;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    overflow: hidden; /* Ensure content doesn't overflow the modal */
}

/* Modal Header */
.textmodal-header {
    margin-bottom: 15px;
}

.textmodal-header h2 {
    margin: 0;
    font-size: 1.2em;
    color: #333;
}

/* Textarea */
.textmodal-textarea {
    width: calc(100% - 20px); /* Adjust width to include padding */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
    box-sizing: border-box; /* Include padding and border in element's width and height */
    font-size: 1em; /* Ensure text size is comfortable */
    margin-bottom: 20px; /* Space between textarea and buttons */
}

/* Buttons Container */
.textmodal-buttons {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    gap: 15px; /* Ensure visible space between buttons */
    margin-top: 10px; /* Add some margin above the buttons container */
}

.textmodal-button {
    background-color: #007bff;
    color: white;
    border: none;
    margin-right: 10px;
    padding: 10px 20px;
    border-radius: 2px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.textmodal-button:hover {
    background-color: #cf8c28;
}

.textmodal-button.cancel {
    background-color: #6c757d;
}

.textmodal-button.cancel:hover {
    background-color: #5a6268;
}
