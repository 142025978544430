.employee-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient( 76.3deg,  rgba(44,62,78,1) 12.6%, rgba(69,103,131,1) 82.8% );

    background-color: #f4f4f9;
    min-height: 100vh;
}

.employee-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #6c757d;
    /* background-color: #394253; */
    /* background-image: linear-gradient( 76.3deg,  rgba(44,62,78,1) 12.6%, rgba(69,103,131,1) 82.8% ); */
    background-image: linear-gradient(-20deg, #616161 0%, #616161, 100%);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
}

.company-logo img {
    margin-left: 20px;
    height: 55px;
}

.profileicon {
    height: 30px;
    padding-right: 4px;
    cursor: pointer;
}

.header-title {
    position: absolute;
    left: 50%;
    color: #FF944F;
    transform: translateX(-50%);
    font-size: 28px;
    font-weight: 800;
   
}
.header-title h1{
    
}
    

.employee-info {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.employee-name {
    font-size: 16px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}
.employee-details {
    display: flex;
    flex-direction: column;
}
.employee-job{
    color:rgb(239, 164, 59);
    font-weight: 520;
    cursor: pointer;
}

.employee-body {
    width: 80%;
    margin-top: 20px;
    background-color: #d9d1d1;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.goals-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #fff;
}

.goals-table th, .goals-table td {
    border: 1px solid #dee2e6;
    padding: 12px 15px;
    text-align: left;
    font-size: 14px;
}

.goals-table th {
    background-color: #f8f9fa;
    font-weight: bold;
}

.goals-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.goals-table tr:hover {
    background-color: #e9ecef;
}

.goals-table button {
    background-color: #f1b861;
    color: #151414;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
}

.goals-table button:hover {
    background-color: #34b8ad;
}

.goals-table select {
    width: 100%;
    padding: 6px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    font-size: 14px;
}

.goals-table select:focus {
    border-color: #80bdff;
    outline: none;
}

.modal-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #fff;
    border: 2px solid #ebc790;
    box-shadow: 24px;
    padding: 16px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
}

.modal-box textarea {
    width: calc(100% - 32px); /* Ensuring it doesn't touch the edges */
    margin-bottom: 16px; /* Adding space between textarea and file upload */
    padding: 8px;
    border: 1px solid #827764;
    border-radius: 4px;
    resize: vertical;
}

.modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
}

.file-upload-label {
    /* background-color: #f0f0f0; */
    /* border: 1px solid #ccc; */
    padding: 0px;
    Padding-left: 0px;
    /* cursor: pointer; */
    border-radius: 4px;
    font-size: 14px;
    color:black;
}

/* .file-upload-label:hover {
    background-color: #d42929;
} */

.close-btn, .submit-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.close-btn {
    background-color: #6c757d;
    color: #fff;
}

.close-btn:hover {
    background-color: #5a6268;
}

.submit-btn {
    background-color: #28a745;
    color: #fff;
}

.submit-btn:hover {
    background-color: #218838;
}

.submit-btn {
    background-color: #218438;
    color: #fff;
}

.submit-btn:hover {
    background-color: #218838;
}

.submit-button-container {
    align-items: right;
    text-align: right; /* Aligns content to the right */
    padding-top: 20px; /* Adds space above the button */
}

.reviewer-comment-box {
    width: 97%;
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #dee2e6;
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.reviewer-comment-box h3 {
    margin-top: 0;
    font-size: 16px;
    font-weight: bold;
}

.reviewer-comment-box p {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.5;
}
.link-input {
    margin-top: 10px;
}

.link-input-label {
    display: block;
    margin-bottom: 5px;
}

.link-input input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}


.goals-header-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.goals-header-container h2 {
  color:rgb(11, 9, 9);
}

.instructions-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    margin-top: 4px;
   font-size: 1.1em;
}
.instructions-btn:hover {
    background-color: #a19989;
    border-radius: 60px;
}

.instructions-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: radial-gradient( circle 1292px at -13.6% 51.7%,  rgba(0,56,68,1) 0%, rgba(0,56,68,1) 51.5%, rgba(0,56,68,1) 88.6% );
    /* background: white; */
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 90%;
    max-width: 800px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: none;
    overflow-y: auto;
    color:white;
}

.instructions-modal.open {
    display: block;
}

.close-instructions {
    margin-top: 10px;
    background: #d5a544;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}
.instructions-modal.open {
    display: block;
}

.instructions-modal h3 {
    color:#f7a84e;
    margin-top: 0;
}

.instructions-modal p {
    margin: 0 0 10px;
    color:#f7a84e;
}

.instructions-modal ol {
    padding-left: 20px;
}

.instructions-modal li {
   font-size: 11px;
}

.instructions-modal button {
    background-color: #e0982c;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.instructions-modal button:hover {
    background-color: #e6633f;
}



/* quarterselection and hisotry button css */
.quarter-history-container {
    display: flex;
    align-items: center;
    gap: 25px; /* Adjust the gap between elements as needed */
    margin-bottom: 0px;
}

.quarter-selector {
    display: flex;
    align-items: center;
    padding-bottom: 0px;
    margin-top: 13.5px;
   
   
}

.quarter-selector label {
    margin-right: 10px;
    font-weight: bold;
    font-size: 1rem;
    color: #333;
    
}

.quarter-selector select {
    width:50%;
    cursor: pointer;
    padding: 5px 1px 6px 7px;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    color: #333;
    outline: none;
    transition: border-color 0.3s;
  
}

.quarter-selector select:focus {
    border-color: #ec9d20;
}

.history-btn {
    padding: 6px 12px;
    font-size: 1rem;
    color: black;
    background-color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: background-color 0.3s;
}

.history-btn:hover {
    background-color: #efb43f;
}

.error-message {
    display: block; /* Forces the error message to start on a new line */
    color: red;
    font-size: 12px;
    margin-left: 5px;
    margin-top: 3px; /* Space between input and error message */
}

/* error-overlay container */
.error-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it appears above other content */
}

/* quarter not select status message */

/* Employee.css */

.status-message {
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    background-image: radial-gradient( circle 1292px at -13.6% 51.7%,  rgba(0,56,68,1) 0%, rgba(0,56,68,1) 51.5%, rgba(0,56,68,1) 88.6% );

    color: #fafafa; /* Dark red text for error */
    padding: 15px;
    border-radius: 5px;
    z-index: 1000; /* Ensure the message appears above other content */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.status-message p {
    margin: 0;
}

.close-icon {
    font-size: 20px;
    color: #e77410; /* Dark red color for the icon */
    cursor: pointer;
    padding-left: 10px;
}

.close-icon:hover {
    color: #a71d2a; /* Darker red on hover */
}


.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: 20px auto;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  

  .table-container {
    overflow-x: auto; /* Enable horizontal scrolling */
}

.goals-table {
    width: 100%;
    border-collapse: collapse;
    min-width: 600px; /* Minimum width for the table to maintain layout */
}

.goals-table th, .goals-table td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
}

/* For smaller screens */
@media (max-width: 768px) {
    .goals-table thead {
        display: none; /* Hide table headers */
    }

    .goals-table, .goals-table tbody, .goals-table tr, .goals-table td {
        display: block;
        width: 100%;
    }

    .goals-table tr {
        margin-bottom: 15px; /* Space between rows */
    }

    .goals-table td {
        position: relative;
        padding-left: 50%; /* Space for the label */
        text-align: right;
    }

    .goals-table td::before {
        content: attr(data-label); /* Show labels for cells */
        position: absolute;
        left: 0;
        width: 45%;
        padding: 5px;
        font-weight: bold;
        background-color: #f9f9f9;
        border-right: 1px solid #ddd;
        text-align: left;
    }
}


.instructions-modal li {
    font-size: 14px; /* Adjust font size as needed */
    line-height: 1; /* Adjust line height for better readability */
    margin-bottom: 10px; /* Add spacing between list items */
    color:white; /* Optional: Set a color for better visibility */
  }